<div class="main-content fade-in-content new-year-margin">
  <div class="container-fluid">
    <div class="row justify-content-center align-items-center section-header new-year-padding">
      <h1>{{ title }}</h1>
    </div>

    <div class="row justify-content-center align-items-center visible-for-mobile-block">
      <div class="col px-1 pt-2 pb-4 text-center">
        <ng-container *ngFor="let link of links">
        <button class="btn btn-primary"
                [routerLink]="[ link.target ]" routerLinkActive="active" 
                [routerLinkActiveOptions]="{exact: link.target == '.' }">
          {{ link.title }}
          <ng-container *ngIf="link.children != null">
            <ng-container *ngTemplateOutlet="expandbutton"></ng-container>
          </ng-container>
        </button>
          <div *ngIf="link.children != null" [hidden]="!isActive (link.target)"
               class="w-100 fade-in-content row justify-content-center align-items-center visible-for-mobile-block">
            <div class="col px-1 pt-0 pb-0 text-center">
              <button *ngFor="let child of link.children" class="btn btn-primary btn-secondary"
                      [routerLink]="[link.target, child.target]" routerLinkActive="active">
                {{ child.title }}
              </button>
            </div>
          </div>          
        </ng-container>
      </div>
    </div>

    <div class="row p-0 pb-3 justify-content-center">
      <div class="col keep-width-over1200">
        <div class="holder-paddings bg-white shadow-sm article-holder visible-for-mobile-block">
          <div #mobileOutlet></div>
        </div>    
        <div class="row px-o m-0 pt-5 pb-4 flex-nowrap align-items-start h-100 visible-for-wide-flex">
          <div class="stickers-area-left py-5">
            <table class="stickers-table">
              <ng-container *ngFor="let link of links">
                <input type="hidden" class="stickers-tr-indicator" [id]="link.target" [routerLink]="[ link.target ]" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: link.target == '.' }" />
                <tr class="stickers-tr" 
                    [routerLink]="[ link.target ]" routerLinkActive="active" 
                    [routerLinkActiveOptions]="{exact: true }">
                  <td>
                    <div class="sticker-lvl1-left" [class.d-flex]="link.children">
                      <ng-container *ngIf="link.children == null">
                        {{ link.title }}
                      </ng-container>
                      <ng-container *ngIf="link.children != null">
                        <div class="mr-auto">{{ link.title }}</div>
                        <div><ng-container *ngTemplateOutlet="expandbutton"></ng-container></div>
                      </ng-container>
                    </div>
                  </td>
                  <td><div class="sticker-lvl1-right" [class.sticker-lvl1-right-active]="isActive (link.target)">&nbsp</div></td>
                </tr>
                <ng-container *ngIf="link.children != null">
                  <tr class="stickers-tr fade-in-content">
                    <td style="overflow: hidden;">
                      <div class="sticker-lvl2"
                           [style.margin-top.rem]="lvl2Margin(link)"
                      >
                        <div *ngFor="let child of link.children" class="sticker-lvl2-left" 
                             [routerLink]="[link.target, child.target]" 
                             routerLinkActive="active">
                             {{ child.title }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>
          <div class="stickers-area-right">
            <div class="holder-paddings bg-white shadow-sm article-holder">
              <div #desktopOutlet></div>
            </div>
          </div>  
        </div>
      </div>  
    </div>
  </div>
</div>

<ng-template #routlet>
  <router-outlet></router-outlet> 
</ng-template>

<ng-template #expandbutton>
  <svg class="" viewBox="0 0 19 13" style="margin-left:10px;" class="" width="20" height="14">
    <circle cx="1" cy="9" r="1" stroke-width="1" /><circle cx="8" cy="9" r="1" stroke-width="1" /><circle cx="15" cy="9" r="1" stroke-width="1" />
  </svg>
</ng-template>
