<div id="wrap" class="" (click)="toggleMenuNav(false, $event)">
<!--      <div *ngIf="true" style="position:fixed;top:0px;left:0px;background:black;color:white;z-index:1000000;font-size:1rem;">&nbsp;{{ screenSize }}</div>   -->
      <header class="container-fluid">
        <div class="row">
          <div class="col px-0">
            <img #headbgimg id="headbgimg" class="img-fluid w-100 img-anchor-right" 
              (transitionstart)="transitionStart($event)"
              (transitionend)="transitionEnd($event)"
              [class.img-anchor-right-thenpagescrolled]="pageScrolled" 
              [class.img-anchor-right-for-captive]="isCaptive"
              src="../images/mainheader.png" />
            <div *ngIf="!isCaptive" class="icon-menu visible-hdr-for-mobile-inner" [class.icon-menu-thenpagescrolled]="smallHeader">
              <a (click)="toggleMenuNav(true, $event)">
                <img src="../images/menubutton.svg"/>
              </a>
              <div [class.menunav-expanded]="visibleMenuNav" class="menunav" [class.menunav-thenpagescrolled]="smallHeader">
                <a class="w-100 text-left" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]= "{exact: true}">главная</a>
                <a class="w-100 text-left" routerLink="news" routerLinkActive="active">новости</a>
                <a class="w-100 text-left" routerLink="services" routerLinkActive="active">услуги</a>
                <a class="w-100 text-left" routerLink="bim" routerLinkActive="active">документы</a>
                <a class="w-100 text-left" routerLink="about" routerLinkActive="active">о компании</a>
                <a class="w-100 text-left" routerLink="contacts" routerLinkActive="active">контакты</a>
              </div>
            </div>

          </div>
        </div>

        <div class="row w-100 pos-head-row-1"> 
          <div class="col-8 font-head font-size-head-main" >
                <table class="h-100">
                 <tr class="align-top">
                  <td class="image-holder">
                    <img class="img-fluid w-100 img-logo" [class.img-logo-hide]="smallHeader && !isCaptive" src="../images/logo.png" routerLink="" />
                  </td>
                  <td class="" style="min-width:190px; max-width:1000px;">
                    <div class="visible-for-height-high" 
                      [class.visible-for-height-high-thenpagescrolled]="smallHeader">ЗАО &quot;Сибирский центр ценообразования в строительстве, промышленности и энергетике&quot;</div>
                    <div class="visible-for-height-low" 
                      [class.visible-for-height-low-thenpagescrolled]="smallHeader">ЗАО &quot;СибЦЦСПЭ&quot;</div>
                  </td>
                </tr>
              </table>

          </div>
          <div class="col-2 font-head pr-1 font-size-head-info visible-hdr-for-wide-block">
            тел. (3812) 24-67-15,<br/>
            e-mail: mail@srccs.ru
          </div>
          <div class="col-2 pr-1 font-head font-size-head-info visible-hdr-for-wide-block">
            644043, г. Омск,<br/>ул. Октябрьская, 33
          </div>
        </div>
        <div class="visible-hdr-for-mobile-inner font-head font-size-head-info head-info-position" 
             [class.head-info-position-thenpagescrolled]="pageScrolled">
             (3812) 24-67-15, mail@srccs.ru<br/>644043, г. Омск, ул. Октябрьская, 33
        </div>

        <div *ngIf="!isCaptive" class="row pos-head-row-2 visible-hdr-for-wide-block" [class.pos-head-row-2-thenpagescrolled]="pageScrolled">         
          <div class="col topnav">
            <a routerLink="" routerLinkActive="active" [routerLinkActiveOptions]= "{exact: true}">главная</a>
            <a routerLink="news" routerLinkActive="active">новости</a>
            <a routerLink="services" routerLinkActive="active">услуги</a>
            <a routerLink="bim" routerLinkActive="active">документы</a>
            <a routerLink="about" routerLinkActive="active">о компании</a>
            <a routerLink="contacts" routerLinkActive="active">контакты</a>
          </div>
        </div>

<!-- Uncomment for NewYear 
<div style="height: 30px; background: url(/images/girlianda_uguide_ru_1.gif) repeat-x 100%;"></div>
-->

      </header>
      <router-outlet></router-outlet>

      <!--<div>{{ '"' + route.url + '"' }}</div>   <div> {{ urlName }}</div>  -->
    </div>
     
    <footer *ngIf="!isCaptive" class="container-fluid px-2 py-1 w-100">
            <div class="row p-0 m-0 w-100 h-100 align-items-center"> 
              <div class="col px-0">
                <div>Copyright &copy; 2019-2023<br/>
                  ЗАО &quot;СибЦЦСПЭ&quot;
                </div>
              </div>
              <div class="col visible-hdr-for-wide-block">
                      <div class="visible-hdr-for-wide-block"><a  routerLink="">главная</a>
                      <a  routerLink="/news">новости</a>
                      <a  routerLink="/services">услуги</a>
                      <a  routerLink="/bim">документы</a>
                      <a  routerLink="/about">о компании</a>
                      <a  routerLink="/contacts">контакты</a></div>
              </div>
              <div class="col px-0 text-right">
                <div>
                  644043, г. Омск,<br/>ул. Октябрьская, 33
                </div>
                <div>
                  тел. (3812) 24-67-15,<br/>e-mail: mail@srccs.ru
                </div>

<!--             
                <div>
                  Caliper icon made by <a href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>,<br/>
                  Brick icon made by <a href="https://www.flaticon.com/authors/pixel-perfect">Pixel perfect</a>,<br/>
                  Budget icon made by <a href="https://www.flaticon.com/authors/linector">Linector</a>,<br/>
                  Graduate cap icon made by <a href="https://www.flaticon.com/authors/freepik">Freepik</a><br/>
                  from <a href="https://www.flaticon.com/">www.flaticon.com</a>
                </div>
-->
              </div>
            </div>
    </footer>  