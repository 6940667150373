import { Injectable } from "@angular/core";
import { BimItem } from "./BimItem.model";
import { srccsWebServiceDataSource } from "./srccsWebService.datasource";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class BimRepository {
  private bimContent: BimItem[] = [];
  constructor(private api: srccsWebServiceDataSource) {
  }

  getBim(): BimItem[] {
    return this.bimContent;
  }

  public async prepareData(){
    console.log ("prepare bimContent...");
    this.bimContent = await this.api.getData("bim").toPromise();
    console.log ("bimContent prepared",this.bimContent);
  }

  public itemStickerTitle (item: BimItem, wide: number) {
    return (item.ShortTitle != null && item.ShortTitle.length > 0) ? item.ShortTitle.substring(0, wide) : item.Path.substring(0,wide);
  }
  public itemTitle (item: BimItem) {
    return (item.Title != null && item.Title.length > 0) ? item.Title : item.Path;
  }
}
