<div class="fade-in-content">
  <p *ngIf="bimRepository.getBim() == null" class="article-header">
    Документы не найдены :(
  </p>
  <p *ngIf="bimRepository.getBim() != null && activePart == null && selectedData == null" class="article-header">
    Выберите раздел
  </p>

  <p *ngIf="activePart != null" class="article-header mb-0 pb-0 text-left">
    {{ bimRepository.itemTitle(activePart) }}
  </p>
  <p *ngIf="activePart != null && activePart.Description != null" class="article-text quote m-0">
    {{ activePart.Description }}
  </p>
  <p *ngIf="activeChapter != null" class="article-header mb-0 pb-0 text-left">
    <span style="font-size:80%">{{ bimRepository.itemTitle(activeChapter) }}</span>
  </p>
  <p *ngIf="activeChapter != null && activeChapter.Description != null" class="article-text quote m-0">
    {{ activeChapter.Description }}
  </p>
  <div *ngIf="selectedData != null && selectedData.length>0 && selectedData[0].ItemType == 'f'">
    <ul class="article-text pt-3">
      <ng-container *ngFor="let docfile of selectedData">
        <li>
          <a class="link-inline" target="_blank" href="{{getPath(docfile)}}">{{bimRepository.itemTitle(docfile)}}</a>
          <p *ngIf="docfile.Description != null" class="article-text quote mx-0 mb-1" style="margin-top: -0.25rem !important; ">
            <span style="font-size:75%;">{{ docfile.Description }}</span>
          </p>
          <div *ngIf="docfile.Features != null" class="article-text quote mx-0 mb-1" style="margin-top: -0.25rem !important; ">
            <ul class="article-text pt-3">
              <ng-container *ngFor="let feature of docfile.Features">
                <li>
                  <span style="font-size:75%;">{{ feature }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="selectedData != null && selectedData.length>0 && selectedData[0].ItemType == 'c'">
    <ng-container *ngFor="let docfolder of selectedData">
      <p class="article-header article-text p4 pt-3 my-0 pb-0">
        <button (click)="docfolder.Expanded=!docfolder.Expanded" onclick="this.blur();" class="unselectable bim-folder-expander">
          <img *ngIf="!docfolder.Expanded" height="20" width="20" src="/images/down-arrow.png" />
          <img *ngIf="docfolder.Expanded" height="20" width="20" src="/images/up-arrow.png" />
        </button>
      <span style="padding-left: 0.5rem;">{{ bimRepository.itemTitle(docfolder) }}</span></p>
      <p *ngIf="docfolder.Description != null" class="article-text quote m-0 mb-1">
        <span style="font-size:85%">{{ docfolder.Description }}</span>
      </p>
      
      <ul *ngIf="docfolder.Expanded" class="article-text">
        <ng-container *ngFor="let docfile of docfolder.Childs">
          <li>
            <a class="link-inline" target="_blank" href="{{getPathWithSubFolder(docfile, docfolder )}}">{{bimRepository.itemTitle(docfile)}}</a>
            <p *ngIf="docfile.Description != null" class="article-text quote mx-0 mb-1" style="margin-top: -0.25rem !important; ">
              <span style="font-size:75%;">{{ docfile.Description }}</span>
            </p>
            <div *ngIf="docfile.Features != null" class="article-text quote mx-0 mb-1" style="margin-top: -0.25rem !important; ">
              <ul class="article-text pt-1 features-list">
                <ng-container *ngFor="let feature of docfile.Features">
                  <li class="features-list">
                    <span>{{ feature }}</span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</div>
