import { Component, HostListener, AfterViewInit, ViewChild, ViewContainerRef, OnInit} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AppComponent } from './app.component';
import { BimRepository } from "./model/Bim.repository";
import { BimItem } from "./model/BimItem.model";

@Component({
    selector: "sections",
    templateUrl: "sections.component.html"
})


export class SectionsComponent {
  public title: String;
  public links;
  public currroute: String;
  private bimContent: BimItem[] = [];

  constructor (private activeRoute: ActivatedRoute, private appl: AppComponent, private bimRepository: BimRepository) {
  }

  @ViewChild('mobileOutlet', { read: ViewContainerRef }) _mobileOutlet; 
  @ViewChild('desktopOutlet', { read: ViewContainerRef }) _desktopOutlet; 
  @ViewChild('routlet') _routlet; 

  async ngOnInit() {
    await this.bimRepository.prepareData();
    this.currroute = this.activeRoute.snapshot.url[0].path;

    switch (this.currroute) {
      case "services": {
        this.title="Услуги";
        this.links = [ { target: "smets", title: "сметы",
                           children: [ { target: "progs", title: "программные продукты" }, 
                                       { target: "indexes", title: "индексы и ценники" } ]
                       },
                       { target: "resources", title: "ресурсы",
                           children: [ { target: "monitoring", title: "мониторинг" }] 
//                                       { target: "transport-schemes", title: "транспортные схемы" } 
                       },
                       { target: "norms", title: "нормативы", 
                           children: [ { target: "building", title: "строительство" }, 
                                       { target: "repairs", title: "нормативная база" },
                                       { target: "toir", title: "технологические карты" },
                                       { target: "asutp", title: "АСУ ТП" } ]
                       },
                       { target: "training", title: "обучение", 
                            children: [ { target: "course", title: "курсы" }, 
                                        { target: "seminar", title: "семинары" } ]
                       }
                     ];
        break;
      }
      case "about": {
        this.title="О компании";
        this.links = [ { target: "history", title: "история"},
                       { target: "heads", title: "руководство"},
                       { target: "awards", title: "достижения"},
                       { target: "portfolio", title: "партнеры"},
                       { target: "gallery", title: "фотогалерея"},
                           //children: [ { target: "norilsk", title: "партнер 1" }, 
                           //            { target: "alrosa1", title: "партнер 2" },
                           //            { target: "alrosa2", title: "партнер 3" },
                           //            { target: "alrosa3", title: "партнер 4" }] }
                ];
        break;
      }
      case "contacts": {
        this.title="Контакты";
        this.links = [ { target: ".", title: "основные"},
                       { target: "detailed", title: "подробно" }
                     ];
        break;
      }
      case "bim": {
        this.title = "Библиотека информационных материалов";
        this.links = [];
        let bimContent = this.bimRepository.getBim();
        if (bimContent != null) {
          let partId = 0;
          bimContent.forEach(section => {
            let linkChilds = [];
            let chapterId = 0;
            section.Childs.forEach(subsection => {
              if (subsection.ItemType == "d")
                linkChilds.push({ target: chapterId, title: this.bimRepository.itemStickerTitle(subsection, 27) });              
              chapterId++;
            });
            if (section.ItemType == "d")
              this.links.push({ target: partId, title: this.bimRepository.itemStickerTitle(section, 22), children: linkChilds.length > 0 ? linkChilds : null });
            partId++;
          });
        }
	      break;
      }
    }
  }

  ngAfterViewInit() {
    this.appl.isWidthTablet().subscribe(value => this.setOutlet(value));
  }

  setOutlet(value: boolean) {
    console.log ("setOutlet", value);
    if (value) {
      if (this._mobileOutlet.length == 0) {
        this._desktopOutlet.clear();
        this._mobileOutlet.createEmbeddedView(this._routlet);
        console.log ("setOutlet create mobile", value);
      }
    } else {
      if (this._desktopOutlet.length == 0) {
        this._mobileOutlet.clear();
        this._desktopOutlet.createEmbeddedView(this._routlet);
      }
    }
  }

  isActive (elid: string) : boolean {
    let result = false;
    if (this.appl.isBrowser && document.getElementById(elid) != null)
      result = document.getElementById(elid).className.indexOf('active') !=-1;
    return result;
  }

  lvl2Margin (link: any) : number {
    let elid = link.target;
    let result = 0;
    if (this.appl.isBrowser && document.getElementById(elid) != null && document.getElementById(elid).className.indexOf('active') !=-1)
      result = 0;
    else
      result = -link.children.length*1.7;   
    return result;
  }

}
