import { Component, OnDestroy } from "@angular/core";
import { Router, RoutesRecognized, ActivatedRoute, NavigationEnd, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, UrlSegment, ParamMap } from "@angular/router";
import { filter, map, mergeMap } from 'rxjs/operators';
import { Subject, Observable, SubscriptionLike, timer } from "rxjs";
import { Subscription } from "rxjs/Subscription";
import { BimRepository } from "./model/Bim.repository";
import { BimItem } from "./model/BimItem.model";

@Component({
  selector: "bim",
  templateUrl: "bim.component.html",
  styleUrls: ['./bim.component.css']
})
export class BimComponent implements OnDestroy {
  public activePart: BimItem;
  public activeChapter: BimItem;
  public selectedData: BimItem[];
  private paramSubscription: Subscription;

  constructor(private router: Router, private activeRoute: ActivatedRoute, public bimRepository: BimRepository) {
    this.paramSubscription = activeRoute.paramMap.subscribe(
      (params: ParamMap): void => {
        this.setPath(params.get("section"), params.get("subsection"));
      }
    );
  }
  private setPath(section: string, subsection: string) {
    //console.log("setPath", section, parseInt(section), Number(section), +section);//, subsection, parseInt(subsection));

    if (section == null) return;
    this.selectedData = null;
    this.activePart = null;
    this.activeChapter = null;
    let part = +section; // + - string to numeric
    let chapter = +subsection;

    let bimData = this.bimRepository.getBim();

    if (bimData == null || isNaN(part) || part < 0 || part >= bimData.length) {
      this.router.navigate(["/bim"]);
      return;
    }

    this.activePart = bimData[part];
    if (subsection != null && (isNaN(chapter) || chapter < 0 || chapter >= bimData[part].Childs.length)) {
      this.router.navigate(["/bim", part]);
      return;
    }
    else {
      if (subsection != null) {
        this.activeChapter = this.activePart.Childs[chapter];
        this.selectedData = this.activeChapter.Childs;
      } else {
        if (this.activePart.Childs[0].ItemType == "f") {
          this.selectedData = this.activePart.Childs;
        }
      }
    }
    //console.log("selectedData", this.activePart, this.selectedData);
  }

  public getPath(item: BimItem) {    
    return this.getPathWithSubFolder(item, null);
  }

  public getPathWithSubFolder(item: BimItem, folder: BimItem) {
    let fullPath = [];
    fullPath.push("files/bim/");
    fullPath.push(this.activePart.Path);
    fullPath.push("/");
    if (this.activeChapter != null) {
      fullPath.push(this.activeChapter.Path);
      fullPath.push("/");
    }
    if (folder != null) {
      fullPath.push(folder.Path);
      fullPath.push("/");
    }
    fullPath.push(item.Path);
    return fullPath.join("");
  }
  
  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }
}
