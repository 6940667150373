import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";

import { routing } from "./app.routing";

import { AppComponent } from './app.component';
import { HomeComponent } from "./home.component";
import { NewsComponent } from "./news.component";
import { BimComponent } from "./bim.component";
import { SectionsComponent } from "./sections.component";

import { CaptiveComponent } from "./captive/captive.component";
import { CounterDirective } from "./captive/counter.directive";
import { PhoneMaskingDirective } from "./captive/phone.directive";

import { ServicesSmetsProgsComponent } from "./services/smets/progs.component";
import { ServicesSmetsIndexesComponent } from "./services/smets/indexes.component";
import { ServicesResourcesComponent } from "./services/resources.component";
import { ServicesResourcesMonitoringComponent } from "./services/resources/monitoring.component";

import { NotFoundComponent } from "./notfound.component";

import { SafeHtmlPipe } from "./model/safeHtml.pipe";
import { NumberSpaceSeparatorPipe } from "./model/numberSpaceSeparator.pipe";
import { SafeUrlPipe } from "./model/safeUrl.pipe";

import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';

import {TransferHttpCacheModule} from '@nguniversal/common';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree { return super.parse(url.toLowerCase()); }
}

@NgModule({
    imports: [BrowserModule.withServerTransition({ appId: 'center' }), BrowserTransferStateModule, routing, CommonModule, 
              HttpClientModule, HttpClientJsonpModule, 
              FormsModule, BrowserAnimationsModule, MatProgressBarModule],
    providers: [SafeHtmlPipe, SafeUrlPipe, NumberSpaceSeparatorPipe
                , { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
               ],
    declarations: [AppComponent, HomeComponent, NewsComponent, BimComponent, SectionsComponent, CaptiveComponent, 
        ServicesSmetsProgsComponent, 
        ServicesSmetsIndexesComponent, 
        ServicesResourcesMonitoringComponent, 
        CounterDirective, PhoneMaskingDirective, 
        SafeHtmlPipe, SafeUrlPipe, NumberSpaceSeparatorPipe,
        NotFoundComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

